import Directory from '../../components/directory/directory.component';

const Home = () => {
  

  return (
    <Directory></Directory>
  );
}

export default Home;