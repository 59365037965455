import { useContext } from 'react';
import { CartContext } from '../../contexts/cart.context';
import { useNavigate } from 'react-router-dom';

import Button from '../button/button.component';
import CartItem from '../cart-item/cart-item.component';
import {CartDropdownContainer, CartItems, EmptyMessage} from './cart-dropdown.styles.jsx';

const CartDropdown = () => {
    const { cartItems, setIsCartOpen } = useContext(CartContext)
    const navigate = useNavigate()

    const checkoutHandler = () => {
        navigate("/checkout")
        setIsCartOpen(false)
    }

    return (
        <CartDropdownContainer>
            <CartItems>
                {cartItems.length ? (
                    cartItems.map((item)=> <CartItem key={item.id} cartItem={item} />)
                ):(
                <EmptyMessage>Your cart is empty</EmptyMessage>
                )}
            </CartItems>
            <Button onClick={checkoutHandler} > GO TO CHECKOUT</Button>
        </CartDropdownContainer>
    );
}

export default CartDropdown;